import React, { createContext, FC, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Box, Switch, Typography } from '@mui/material'

import { rolesEnum } from 'services/users/types'

import NavigationTabs from 'components/common/NavigationTabs'

const tabs = [
  { id: 'mails', label: 'MAILS', allowedRoles: [rolesEnum.Admin, rolesEnum.Manager] },
  { id: 'scrap-tasks', label: 'SCRAP TASKS', allowedRoles: [rolesEnum.Admin] },
]

const VersionContext = createContext<{ version: 'v1' | 'v2' }>({ version: 'v2' })
export const useVersionContext = () => useContext(VersionContext)

const Mails: FC = () => {
  const [isV2, setIsV2] = useState(true)

  const handleChangeVersion = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsV2(checked)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <NavigationTabs tabs={tabs} basePath="/mails" />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography>V1</Typography>
          <Switch checked={isV2} onChange={handleChangeVersion} />
          <Typography>V2</Typography>
        </Box>
      </Box>
      <VersionContext.Provider value={{ version: isV2 ? 'v2' : 'v1' }}>
        <Outlet />
      </VersionContext.Provider>
    </Box>
  )
}
export default Mails
