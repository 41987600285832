import type { FC } from 'react'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'

import useAuth from 'hooks/useAuth'

import { rolesEnum } from 'services/users/types'

import NavigationTabs from 'components/common/NavigationTabs/index'

export const Dashboard: FC = () => {
  const tabs = [
    { id: 'charts', label: 'CHARTS', allowedRoles: [rolesEnum.Admin, rolesEnum.Manager] },
    { id: 'bot-station', label: 'BOT STATION', allowedRoles: [rolesEnum.Admin] },
    { id: 'logs', label: 'LOGS', allowedRoles: [rolesEnum.Admin] },
    { id: 'reports', label: 'REPORTS', allowedRoles: [rolesEnum.Admin, rolesEnum.Manager] },
  ]
  const { user } = useAuth()
  const filteredTabs = tabs.filter((tab) => user && tab.allowedRoles.includes(user?.roleId))
  return (
    <Box sx={{ height: 'calc(100vh - 80px)' }}>
      <NavigationTabs tabs={filteredTabs} basePath="/bot-dashboard" />
      <Outlet />
    </Box>
  )
}
