import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { ShowMessageModal } from 'components/mails/mails/ShowMessageModal/index'
import TableToolbar from 'components/table/TableToolbar/index'

import { MailTabActions } from 'pages/mails/OldMailsTab/index'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: MailTabActions
  setModal: React.Dispatch<React.SetStateAction<MailTabActions>>
  singleRow: any
  onFilterApplyClick: () => void
}

export const MailsTabToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  singleRow,
  setModal,
  onFilterApplyClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        onFilterApplyClick={onFilterApplyClick}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              {selectedRows.length === 1 ? (
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-toolbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={!!anchorEl}
                  onClose={handleCloseMenu}
                >
                  {selectedRows.length === 1 ? (
                    <MenuItem
                      onClick={() => {
                        setModal('SHOW_MESSAGE')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">Show message</Typography>
                    </MenuItem>
                  ) : null}
                </Menu>
              ) : null}
            </Box>
          </Box>
        }
      />

      {(modal === 'SHOW_MESSAGE' || modal === 'SHOW_MESSAGE_SINGLE') && (
        <ShowMessageModal
          onClose={() => setModal('')}
          mail={modal === 'SHOW_MESSAGE_SINGLE' ? singleRow : selectedRows[0]}
        />
      )}
    </>
  )
}
