function shuffle(array: string[]) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

const generatePassword = (length: number, wishlist: string) => {
  const newPassword = Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('')

  return newPassword
}

const numbers = '0123456789'
const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const lowerCase = 'abcdefghijklmnopqrstuvwxyz'

export const passwordGenerator = () => {
  const num = generatePassword(5, numbers)
  const up = generatePassword(5, upperCase)
  const low = generatePassword(6, lowerCase)

  const array = [...num.split(''), ...up.split(''), ...low.split('')]
  const password = shuffle(array).join('')
  return password
}
