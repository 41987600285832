import { baseURL } from 'api'
import { FC } from 'react'

import FileOpenIcon from '@mui/icons-material/FileOpen'
import Typography from '@mui/material/Typography/Typography'

interface Props {
  fileName: string
  mailId: number
}

export const FilePreviewLinkNew: FC<Props> = ({ fileName, mailId }) => {
  return (
    <a
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minWidth: '0',
        maxWidth: '120px',
        border: '1px solid #007bff',
        borderRadius: '4px',
        padding: '8px',
        cursor: 'pointer',
        gap: '4px',
      }}
      href={`${baseURL || ''}/api/new_mails/attachments/${mailId}/${fileName}`}
      target="_blank"
      rel="noreferrer"
    >
      <FileOpenIcon sx={{ color: '#007bff' }} />
      <Typography
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {fileName}
      </Typography>
    </a>
  )
}
