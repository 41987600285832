import Box from '@mui/material/Box/Box'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useGetMailMessageNewQuery } from 'services/dashboard/api'
import { Mail } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal/index'
import { FilePreviewLinkNew } from 'components/mails/mails/FilePreviewLinkNew/index'

interface Props {
  onClose: () => void
  mail: Mail
}

export const ShowMessageNewModal = ({ onClose, mail }: Props) => {
  const { data, isLoading } = useGetMailMessageNewQuery({
    catcherId: mail.catcherId,
    mailId: mail.id,
  })

  return (
    <AppModal title={mail.subject} onClose={onClose} maxWidth={700} loading={isLoading}>
      <AppModal.Wrapper sx={{ width: '100%' }}>
        <AppModal.ContentBox sx={{ height: 'fit-content', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Sender:</b>
              <br /> {mail.sender}
            </Box>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Recipient:</b>
              <br /> {mail.recipient}
            </Box>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Received At:</b>
              <br /> {formatToLocaleTimezone(mail.receivedAt)}
              <br />
              <b>Saved At:</b>
              <br /> {formatToLocaleTimezone(mail.savedAt)}
            </Box>
          </Box>
          <iframe srcDoc={data?.htmlContent} style={{ width: 650, height: 500, border: 'none' }} />
        </AppModal.ContentBox>

        <AppModal.ButtonsBox>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            {data?.files?.map((file) => (
              <FilePreviewLinkNew key={file} mailId={mail.id} fileName={file} />
            ))}
          </Box>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
