import { ChangeEvent, FC, ReactNode } from 'react'

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  searchValue: string
  onExportButtonClick?: () => void
  rightContent?: ReactNode
  onFilterApplyClick?: () => void
  onRefreshClick?: () => void
}

const TableToolbar: FC<Props> = ({
  onChange,
  searchValue,
  onExportButtonClick,
  rightContent,
  onFilterApplyClick,
  onRefreshClick,
}) => {
  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
          pt: 1,
        }}
      >
        <Box>
          <GridToolbarQuickFilter
            sx={{ minWidth: '400px', mr: 1 }}
            variant="outlined"
            size="small"
            onChange={onChange}
            value={searchValue}
          />
          <GridToolbarFilterButton />
          {onFilterApplyClick && <Button onClick={onFilterApplyClick}>Apply filters</Button>}
          {onRefreshClick && (
            <Button onClick={onRefreshClick} size="small">
              <RefreshIcon />
              <Box sx={{ ml: 1 }}>Refresh</Box>
            </Button>
          )}
        </Box>

        <Box display="flex">
          {onExportButtonClick && (
            <Button
              size="small"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={onExportButtonClick}
            >
              Export
            </Button>
          )}

          {rightContent}
        </Box>
      </GridToolbarContainer>
    </>
  )
}

export default TableToolbar
