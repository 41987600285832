import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { Typography } from '@mui/material'
import Box from '@mui/material/Box/Box'

import { showNotification } from 'utils/showNotification'

import { useGetSalesPlatfromsToSelectQuery, useRegisterOnSaleMutation } from 'services/bookings/api'
import { BookingsListing } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

import { REGISTER_ON_SALE_SCHEMA } from './validation'

interface Props {
  listing: BookingsListing
  onClose: () => void
}

interface FormType {
  salesPlatformId: {
    value: string
    name: string
  }
  salePriceEur: number
  priceOrCoefficient: {
    value: string
    name: string
  }
  platformUserId?: {
    value?: string
    name?: string
  } | null
  eventId?: string
  splitType: {
    value: string
    name: string
  }
  listings: {
    listingId?: number
    salePriceEur?: number
    priceOrCoefficient: {
      value: string
      name: string
    }
    splitType: {
      value: string
      name: string
    }
    numberOfTickets: number
  }[]
  platformListingId?: string | null
  isOriginalAdded: boolean
}

export const SPLITING_TYPE_OPTIONS = [
  { value: '0', name: 'Only all' },
  { value: '1', name: "Don't leave one left" },
  { value: '2', name: 'sell in x2' },
  { value: '3', name: 'sell in x3' },
]

const PRICE_OR_COEFFICIENT_OPTIONS = [
  { value: '1', name: 'Price, EUR' },
  { value: '2', name: 'Coefficient' },
]

export const RegisterOnSaleModal = ({ listing, onClose }: Props) => {
  // const [isAddCloneDialogOpen, setIsAddCloneDialogOpen] = useState(false)
  const { data: platforms } = useGetSalesPlatfromsToSelectQuery()
  const [registerOnSale, { isLoading }] = useRegisterOnSaleMutation()
  const platformsToSelect = platforms?.data?.map((platform) => ({
    name: platform.name,
    value: platform.id.toString(),
  }))

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(REGISTER_ON_SALE_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      salePriceEur: listing.priceEur,
      salesPlatformId: {
        value: '',
        name: '',
      },
      priceOrCoefficient: PRICE_OR_COEFFICIENT_OPTIONS[0],
      splitType: {
        value: '',
        name: '',
      },
      platformUserId: {
        value: '',
        name: '',
      },
      eventId: '',
      listings: [],
      platformListingId: '',
      isOriginalAdded: false,
    },
  })
  const { fields: clones, replace, prepend } = useFieldArray({ control, name: 'listings' })

  const wIsOriginalAdded = watch('isOriginalAdded')

  const splitType = watch('splitType')
  const salePrice = watch('salePriceEur')
  const priceOrCoefficient = watch('priceOrCoefficient')

  useEffect(() => {
    replace(clones?.map((clone) => ({ ...clone, splitType: splitType })))
  }, [JSON.stringify(splitType)])
  useEffect(() => {
    replace(clones?.map((clone) => ({ ...clone, salePriceEur: salePrice })))
  }, [salePrice])
  useEffect(() => {
    replace(clones?.map((clone) => ({ ...clone, priceOrCoefficient: priceOrCoefficient })))
  }, [JSON.stringify(priceOrCoefficient)])

  useEffect(() => {
    setValue('salePriceEur', +priceOrCoefficient === 2 ? 1 : listing.priceEur)
  }, [priceOrCoefficient])

  const salesPlatform = watch('salesPlatformId')

  useEffect(() => {
    if (salesPlatform?.value !== '123') {
      setValue('eventId', '')
      setValue('platformUserId', {
        value: '',
        name: '',
      })
    }
  }, [salesPlatform])

  useEffect(() => {
    setValue('eventId', listing?.salesPlatformEvents[salesPlatform.value])
  }, [salesPlatform, listing])

  const usersToSelect = platforms?.data
    .find((platform) => platform.id === +salesPlatform?.value)
    ?.platformUsers?.map((user) => ({
      name: user.platformUserName,
      value: user.id + '',
    }))

  const handleAddClone = useCallback(() => {
    prepend({
      splitType: splitType,
      salePriceEur: salePrice,
      priceOrCoefficient: priceOrCoefficient,
      numberOfTickets: 1,
    })
  }, [splitType, salePrice, priceOrCoefficient])

  const onSubmit = async (formValues: FormType) => {
    try {
      await registerOnSale({
        listingId: listing.id,
        platformListingId: formValues.platformListingId,
        salesPlatformUserId: formValues?.platformUserId?.value
          ? +formValues.platformUserId.value
          : null,
        salesPlatformData: formValues?.eventId
          ? {
              eventId: formValues.eventId,
            }
          : null,
        saleUnits: formValues?.listings
          ? formValues.isOriginalAdded
            ? [
                {
                  salePriceEur:
                    +formValues?.priceOrCoefficient?.value === 2 //if only global price added and coefficient
                      ? listing.priceEur * formValues.salePriceEur
                      : formValues.salePriceEur,
                  splitType: +formValues.splitType?.value,
                  numberOfTickets: listing?.tickets?.filter(
                    (ticket) => ticket.saleStatusType !== 10
                  )?.length,
                  // ticketIds: listing?.tickets?.map((ticket) => ticket.id),
                },
                ...formValues.listings.map((clone) => ({
                  salePriceEur: clone.salePriceEur //if sale price of separate clone
                    ? +clone?.priceOrCoefficient?.value === 2 //and used coefficient
                      ? listing.priceEur * clone.salePriceEur // use listing ticket price from get request
                      : clone.salePriceEur // else - use clone price as value
                    : +formValues?.priceOrCoefficient?.value === 2 //if only global price added and coefficient
                    ? listing.priceEur * formValues.salePriceEur
                    : formValues.salePriceEur, // else - use clone price as value
                  //@ts-ignore
                  splitType: +clone.splitType?.value ?? +formValues.splitType?.value,
                  numberOfTickets: clone.numberOfTickets,
                  // ticketIds: clone.ticketIds,
                })),
              ]
            : formValues.listings.map((clone) => ({
                salePriceEur: clone.salePriceEur //if sale price of separate clone
                  ? +clone?.priceOrCoefficient?.value === 2 //and used coefficient
                    ? listing.priceEur * clone.salePriceEur // use listing ticket price from get request
                    : clone.salePriceEur // else - use clone price as value
                  : +formValues?.priceOrCoefficient?.value === 2 //if only global price added and coefficient
                  ? listing.priceEur * formValues.salePriceEur
                  : formValues.salePriceEur, // else - use clone price as value
                //@ts-ignore
                splitType: +clone.splitType?.value ?? +formValues.splitType?.value,
                numberOfTickets: clone.numberOfTickets,
                // ticketIds: clone.ticketIds,
              }))
          : [],
      })
        .unwrap()
        .then(() => {
          showNotification('Successfully registered on sale', 'success')
          onClose()
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
        })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AppModal title="Register On Sale" onClose={onClose} maxWidth={800} loading={isLoading}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ width: '800px' }}>
          <ControlledSelectAutocomplete
            name="salesPlatformId"
            label="Sales Platform"
            errors={errors}
            control={control}
            selectOptions={platformsToSelect}
          />
          {salesPlatform?.value && (
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <ControlledSelectAutocomplete
                name="platformUserId"
                label="User"
                errors={errors}
                control={control}
                selectOptions={usersToSelect}
                sx={{ flex: 1 }}
              />
              {+salesPlatform?.value === 123 && (
                <TextInput
                  name="eventId"
                  label="Event ID"
                  errors={errors}
                  control={control}
                  sx={{ flex: 1 }}
                />
              )}
            </Box>
          )}
          <ControlledSelectAutocomplete
            name="splitType"
            label="Splitting type"
            errors={errors}
            control={control}
            selectOptions={SPLITING_TYPE_OPTIONS}
          />
          <TextInput
            name="platformListingId"
            label="Platform Listing ID (Optional)"
            errors={errors}
            control={control}
          />
          <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
            <TextInput
              name="salePriceEur"
              label={priceOrCoefficient?.value === '1' ? 'Sale Price, EUR' : 'Coefficient'}
              errors={errors}
              control={control}
              sx={{ flex: 1 }}
            />
            <ControlledSelectAutocomplete
              name="priceOrCoefficient"
              control={control}
              label="Price Or Coefficient"
              errors={errors}
              selectOptions={PRICE_OR_COEFFICIENT_OPTIONS}
              sx={{ flex: 1 }}
            />
          </Box>

          <Checkbox label="Add original listing" control={control} name="isOriginalAdded" />

          <Button onClick={handleAddClone}>Add Clone</Button>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2, mt: 2 }}>
            {clones?.map((clone, index) => (
              <Box key={`${clone.id}`} sx={{ display: 'flex', width: '100%', gap: 2 }}>
                <TextInput
                  name={`listings.${index}.numberOfTickets`}
                  label="Number of tickets"
                  errors={errors?.listings?.[index]?.numberOfTickets?.message}
                  control={control}
                  sx={{ flex: 1 }}
                />
                <ControlledSelectAutocomplete
                  name={`listings.${index}.splitType`}
                  label="Splitting type"
                  errors={errors?.listings?.[index]?.splitType?.message}
                  control={control}
                  selectOptions={SPLITING_TYPE_OPTIONS}
                  sx={{ flex: 1 }}
                />

                <TextInput
                  name={`listings.${index}.salePriceEur`}
                  label="Sale Price, EUR"
                  errors={errors?.listings?.[index]?.salePriceEur?.message}
                  control={control}
                  sx={{ flex: 1 }}
                />
                <ControlledSelectAutocomplete
                  name={`listings.${index}.priceOrCoefficient`}
                  control={control}
                  label="Price Or Coefficient"
                  errors={errors?.listings?.[index]?.priceOrCoefficient?.message}
                  selectOptions={PRICE_OR_COEFFICIENT_OPTIONS}
                  sx={{ flex: 1 }}
                />
              </Box>
            ))}
            {errors?.listings?.message && (
              <Typography fontSize={14} color="error">
                {errors.listings.message}
              </Typography>
            )}
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading || (!wIsOriginalAdded && !clones.length)}
            fullWidth={isMobileOnly}
          >
            Register On Sale
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
