import React from 'react'

import { useVersionContext } from '..'
import { NewMailsTab } from '../NewMailsTab'
import { OldMailsTab } from '../OldMailsTab'

export const MailsTabByVersion = () => {
  const { version } = useVersionContext()
  return version === 'v1' ? <OldMailsTab /> : <NewMailsTab />
}
