import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { usePauseBotStationMutation } from 'services/dashboard/api'
import { useGetEventGroupQuery } from 'services/events/api'

import ConfirmModal from 'components/common/ConfirmModal/index'
import { EditBotStationModal } from 'components/dashboard/bot-station/EditBotStationModal/index'
import TableToolbar from 'components/table/TableToolbar/index'

import { BotStationTabMActions } from 'pages/dashboard/BotStationTab/index'

import { CopySettingsModal } from '../CopySettingsModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: BotStationTabMActions
  setModal: React.Dispatch<React.SetStateAction<BotStationTabMActions>>
  singleRow: any
  hideActions?: boolean
}

export const BotStationToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onSearchChange,
  modal,
  setModal,
  hideActions,
  singleRow,
}) => {
  const { data: eventGroups } = useGetEventGroupQuery()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [pauseBotStation, { isLoading }] = usePauseBotStationMutation()
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    if (selectedRows.length) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const selectedRowIds = selectedRows.map((v: any) => v?.id)
  const confirmMessage =
    modal === 'PAUSE'
      ? selectedRowIds.length === 1
        ? `Are you sure you want to pause selected Bot Station?`
        : `Are you sure you want to pause selected Bot Stations?`
      : `Are you sure you want to pause selected Bot Station?`
  const confirmPause = () => {
    pauseBotStation({
      botStationsIds: modal === 'PAUSE' ? selectedRowIds : singleRow,
    })
      .unwrap()
      .then(() => {
        showNotification(
          modal === 'PAUSE' && selectedRows.length > 1
            ? `Selected Bot Stations successfully paused`
            : `Bot Station successfully paused`,
          'success'
        )
        setModal('')
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }

  return (
    <>
      <TableToolbar
        onChange={onSearchChange}
        searchValue={searchValue}
        rightContent={
          hideActions ? null : (
            <Box>
              <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
                Actions
              </Button>
              <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-toolbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={!!anchorEl}
                  onClose={handleCloseMenu}
                >
                  {selectedRows.length === 1 && (
                    <MenuItem
                      onClick={() => {
                        setModal('EDIT')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">Edit Bot Station</Typography>
                    </MenuItem>
                  )}
                  {selectedRows.length >= 1 && (
                    <MenuItem
                      onClick={() => {
                        setModal('PAUSE')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">
                        Pause Bot Station{selectedRows.length > 1 && 's'}
                      </Typography>
                    </MenuItem>
                  )}
                  {selectedRows.length >= 1 && (
                    <MenuItem
                      onClick={() => {
                        setModal('COPY_FROM')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">Copy settings</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          )
        }
      />
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditBotStationModal
          onClose={() => {
            setModal('')
          }}
          botStation={modal === 'EDIT' ? selectedRows[0] : singleRow}
          eventGroups={eventGroups}
        />
      )}
      {(modal === 'PAUSE' || modal === 'PAUSE_SINGLE') && (
        <ConfirmModal
          open={true}
          onConfirm={confirmPause}
          loading={isLoading}
          onClose={() => {
            setModal('')
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
      {modal === 'COPY_FROM' && (
        <CopySettingsModal
          onClose={() => {
            setModal('')
          }}
          stationsIds={selectedRows.map((v: any) => v.id)}
        />
      )}
    </>
  )
}
