import { FC } from 'react'

import { Typography } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'

interface Props {
  fileName: string
  downloadUrl?: string
  isEditForm: boolean
  sx?: BoxProps
}

const DownloadedFile: FC<Props> = ({ fileName, downloadUrl, sx }) => {
  const link = downloadUrl ? `${downloadUrl}?filename=${fileName}&disposition=inline` : ''

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      component="label"
      htmlFor="link"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {link ? (
          <a id="link" href={link} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        ) : (
          <Typography fontSize="16px">{fileName}</Typography>
        )}
      </Box>
    </Box>
  )
}

export default DownloadedFile
