import { yupResolver } from '@hookform/resolvers/yup'
import { baseURL } from 'api'
import React, { FC, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, Button, Typography } from '@mui/material'

import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import {
  useCreateBookingListingMutation,
  useEditBookingListingMutation,
} from 'services/bookings/api'
import { BookingsListing } from 'services/bookings/types'
import {
  useUploadConfirmationFileMutation,
  useUploadOrderScreenshotFileMutation,
} from 'services/purchasing/api'

import { TicketsAutocomplete } from 'components/bookings/listings/CreateListingModal/TicketAutocomplete/TicketsAutocomplete'
import { Tags } from 'components/bookings/listings/tags/Tags'
import { AppModal } from 'components/common/AppModal'
import Checkbox from 'components/common/Checkbox'
import { CustomDropzone } from 'components/common/CustomDropzone/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import DatePicker from 'components/common/DatePicker'
import TextInput from 'components/common/TextInput'

import { createListingModalValidation } from './validations'

const TICKET_FORM_TYPE_OPTIONS = [
  { value: '0', name: 'E-Ticket' },
  { value: '1', name: 'Paper' },
  { value: '2', name: 'Mobile' },
  { value: '3', name: 'Mobile Link' },
]

export const TICKET_FORM_MAP = {
  0: 'E-Ticket',
  1: 'Paper',
  2: 'Mobile',
  3: 'Mobile Link',
} as const

export type TicketFormResponseType = keyof typeof TICKET_FORM_MAP

export const TICKET_COLOR_MAP = {
  0: 'primary',
  1: 'info',
  2: 'success',
  3: 'warning',
} as const

interface CreateListingModalProps {
  listing?: BookingsListing
  onClose: () => void
  clone?: boolean
}

export interface internalTickets {
  id?: any
  seat?: string | null
  row?: string | null
  section?: string | null
  beId?: number | null
}
interface FormValues {
  internalEvent?: any
  eventCategoryId: {
    value: string
    name: string
  }
  priceLocal: number
  orderedDateCet: string
  user:
    | string
    | {
        name: string
        value: string
      }
  password: string
  feeLocal: number
  internalTicketForm: {
    value: string
    name: string
  }
  isInPossession: boolean
  numberOfTickets?: number
  bookingConfirmationFilename: string | File[]
  orderScreenshotFilename: string | File[]
  tagIds: (number | undefined)[]
  bookingId?: string | null
  note?: string | null
  address?: string | null
  telephone?: string | null
  cardId: {
    name: string
    value: string
  }
  fanSection?: string | null

  internalTickets?: internalTickets[]
}

export const CreateListingModal: FC<CreateListingModalProps> = ({
  onClose,
  listing,
  clone = false,
}) => {
  const [createListing, { isLoading: isCreating }] = useCreateBookingListingMutation()
  const [editListing, { isLoading: isEditing }] = useEditBookingListingMutation()

  const [currency, setCurrency] = useState<{ name: string; rateToEur: number }>({
    name: 'EUR',
    rateToEur: 1,
  })
  const [uploadConfirmationFile, { isLoading: isUploading }] = useUploadConfirmationFileMutation()
  const [uploadOrderScreenshot, { isLoading: isUploadingScreenshot }] =
    useUploadOrderScreenshotFileMutation()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
    register,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(createListingModalValidation(currency)),
    defaultValues: {
      internalEvent: listing
        ? {
            name: listing?.eventCategory.eventName,
            id: listing?.eventCategory.eventId.toString(),
            categories: [
              {
                name: listing?.eventCategory.name,
                id: listing?.eventCategory.id.toString(),
                priceLocal: listing.priceLocal,
              },
            ],
            currency: listing?.eventCategory.currency,
          }
        : '',
      eventCategoryId: {
        value: listing?.eventCategory?.id?.toString() || '',
        name: listing?.eventCategory?.name || '',
      },
      priceLocal: listing?.priceLocal || 0,
      orderedDateCet: listing?.orderedDateCet || '',
      user: listing?.user ? { name: listing?.user, value: listing?.password } : '',
      password: listing?.password || '',
      feeLocal: listing?.feeLocal || 0,
      internalTicketForm: {
        value: listing?.ticketForm?.toString() || '0',
        name:
          TICKET_FORM_TYPE_OPTIONS?.find((v) => v.value === listing?.ticketForm?.toString())
            ?.name || 'E-Ticket',
      },
      isInPossession: listing?.isInPossession || false,
      numberOfTickets: 0,
      bookingConfirmationFilename: listing?.bookingConfirmationFilename,
      orderScreenshotFilename: listing?.orderScreenshotFilename,
      tagIds: [],
      bookingId: listing?.bookingId || '0',
      note: listing?.note || '',
      fanSection: listing?.fanSection || '',
      address: listing?.address || '',
      telephone: listing?.telephone || '',
      cardId: { name: listing?.card?.num ?? '', value: listing?.card?.id?.toString() ?? '' },
      internalTickets: listing?.tickets?.map((ticket) => ({ ...ticket, beId: ticket.id })) || [],
    },
  })

  const {
    fields: currentTickets,
    append,
    remove,
    replace,
    update,
  } = useFieldArray({ control, name: 'internalTickets' })
  useEffect(() => {
    if (listing)
      setValue(
        'tagIds',
        listing.tags.map((tag) => tag.id as number)
      )
  }, [])

  const handleCloseModal = () => {
    reset()
    onClose()
  }

  const generatePassword = () => {
    setValue('password', passwordGenerator())
    if (errors?.password?.message) clearErrors('password')
  }

  const event = watch('internalEvent')
  const categoriesToSelect =
    (typeof event === 'object' &&
      event?.categories?.map((category: any) => ({
        name: category.name || '',
        value: category.id || '',
        priceLocal: category.priceLocal,
      }))) ||
    []

  const numberOfTickets = watch('numberOfTickets')

  const createEmptyTickets = () => {
    const arrLength = numberOfTickets ? numberOfTickets - currentTickets.length : 0
    const result = []
    for (let i = 0; i < arrLength; i++) {
      result.push({ seat: null, row: null, section: null })
    }
    return result
  }

  useEffect(() => {
    setCurrency((typeof event === 'object' && event?.currency) || { name: 'EUR', rateToEur: 1 })
  }, [event])

  const eventCategory = watch('eventCategoryId')

  useEffect(() => {
    const category =
      typeof event === 'object' &&
      event?.categories?.find((category: any) => category?.id && +category.id === +eventCategory)
    if (category) setValue('priceLocal', category?.priceLocal || 0)
  }, [eventCategory])

  const isUserSelected = !!watch('user')
  const onSubmit = async (formValues: FormValues) => {
    let confirmationFilename = listing?.bookingConfirmationFilename || ''
    let screenshotFilename = listing?.orderScreenshotFilename || ''

    if (
      typeof formValues.bookingConfirmationFilename === 'object' &&
      formValues.bookingConfirmationFilename.length > 0
    ) {
      const formDataPdf = new FormData()
      formDataPdf.append('bookingConfirmation', formValues.bookingConfirmationFilename[0])

      await uploadConfirmationFile(formDataPdf)
        .unwrap()
        .then(({ resultFilename }) => {
          confirmationFilename = resultFilename
          clearErrors('bookingConfirmationFilename')
          setValue('bookingConfirmationFilename', resultFilename)
          showNotification('Document uploaded', 'success')
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
          return
        })
    }

    if (
      typeof formValues.orderScreenshotFilename === 'object' &&
      formValues.orderScreenshotFilename.length > 0
    ) {
      const formDataImg = new FormData()
      formDataImg.append('orderScreenshot', formValues.orderScreenshotFilename[0])

      await uploadOrderScreenshot(formDataImg)
        .unwrap()
        .then(({ resultFilename }) => {
          screenshotFilename = resultFilename
          clearErrors('orderScreenshotFilename')
          setValue('orderScreenshotFilename', resultFilename)
          showNotification('Screenshot uploaded', 'success')
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
          return
        })
    }

    let submitData = {
      eventCategoryId: +formValues.eventCategoryId.value,
      priceLocal: +formValues.priceLocal,
      feeLocal: +formValues.feeLocal,
      orderedDateCet: formValues.orderedDateCet,
      password: formValues.password,
      tagIds: formValues.tagIds,
      note: formValues.note,
      address: formValues.address,
      fanSection: formValues.fanSection,
      telephone: formValues.telephone,
      user: typeof formValues.user === 'object' ? formValues.user?.name : '',
      cardId: typeof formValues.cardId === 'object' ? formValues.cardId?.value : 0,
      bookingId: formValues.bookingId?.toString() ?? null,
      //numberOfTickets: formValues.numberOfTickets || (formValues?.internalTickets?.length ?? 0),
      ticketForm: +formValues.internalTicketForm?.value,
      isInPossession: formValues.isInPossession,
      tickets:
        formValues?.internalTickets?.map(({ beId, id, ...restTicketData }) => ({
          ...restTicketData,
          ...(beId && { id: beId }),
          row: restTicketData?.row?.toString() || null,
          seat: restTicketData?.seat?.toString() || null,
          section: restTicketData.section || null,
        })) || [],
      bookingConfirmationFilename: confirmationFilename || '',
      orderScreenshotFilename: screenshotFilename || '',
    }

    if (listing) {
      if (!clone) {
        await editListing({ ...submitData, id: listing.id, cardId: +formValues.cardId.value })
          .unwrap()
          .then(() => {
            showNotification('Listing updated', 'success')
            onClose()
          })
          .catch(() => {
            showNotification('Error. Try again later', 'error')
          })
      } else {
        await createListing({ ...submitData, cardId: +formValues.cardId.value })
          .unwrap()
          .then(() => {
            showNotification('Listing cloned', 'success')
            onClose()
          })
          .catch(() => {
            showNotification('Error. Try again later', 'error')
          })
      }
    } else {
      await createListing({ ...submitData, cardId: +formValues.cardId.value })
        .unwrap()
        .then(() => {
          showNotification('Listing created', 'success')
          onClose()
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
        })
    }
  }

  return (
    <AppModal
      title={`${listing ? (clone ? 'Clone' : 'Edit') : 'Create'} listing`}
      onClose={onClose}
      loading={isCreating || isEditing || isUploading || isUploadingScreenshot}
    >
      <AppModal.Wrapper
        sx={{ width: '800px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <AppModal.ContentBox sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 2 }}>
              <ControlledSelectAutocomplete
                control={control}
                errors={errors}
                name="internalEvent"
                label="Event"
                url={`/api/events/suggestion`}
                transformOption={({ name, id, categories, currency, eventGroupName }) => ({
                  name: name + `(id: ${id}, group name: ${eventGroupName})`,
                  value: id,
                  categories,
                  currency,
                })}
              />
              <ControlledSelectAutocomplete
                name="eventCategoryId"
                label="Category"
                errors={errors}
                disabled={!event}
                control={control}
                selectOptions={categoriesToSelect}
              />
              <TextInput
                name="priceLocal"
                label={`Tickets Price, ${currency.name}`}
                disabled={!eventCategory}
                errors={errors}
                control={control}
              />
              <TextInput
                control={control}
                errors={errors}
                name="feeLocal"
                disabled={!eventCategory}
                label="Listing Fee"
                type="number"
              />
              <ControlledSelectAutocomplete
                sx={{ height: '70px' }}
                name="internalTicketForm"
                label="Ticket Form"
                disabled={!eventCategory}
                control={control}
                errors={errors}
                selectOptions={TICKET_FORM_TYPE_OPTIONS}
                // defaultValue={TICKET_FORM_TYPE_OPTIONS[0].value}
              />
              <DatePicker
                name="orderedDateCet"
                label="Ordered Date"
                disabled={!eventCategory}
                errors={errors}
                control={control}
              />
              <TextInput
                name="bookingId"
                label="Booking ID"
                disabled={!eventCategory}
                errors={errors}
                control={control}
              />
              {currentTickets.length === 0 && !listing && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextInput
                    name="numberOfTickets"
                    label="Number of tickets"
                    disabled={!eventCategory}
                    errors={errors}
                    control={control}
                  />
                  <Button
                    sx={{ height: 56 }}
                    variant="contained"
                    color="primary"
                    onClick={() => append(createEmptyTickets())}
                  >
                    <AddIcon />
                  </Button>
                </Box>
              )}
              <TicketsAutocomplete
                append={append}
                remove={remove}
                replace={replace}
                update={update}
                currentTickets={currentTickets}
                listingErrors={errors}
              />
              <TextInput label="Fan Section" errors={errors} control={control} name="fanSection" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                gap: 2,
                justifyContent: 'flex-start',
              }}
            >
              <ControlledSelectAutocomplete
                name="user"
                label="User"
                control={control}
                errors={errors}
                url={`/api/bookings/listings/suggested-users`}
                transformOption={(item) => ({
                  name: item.username,
                  value: item.username,
                  password: item.password,
                })}
                onChange={(v: any) => {
                  setValue('password', v.password)
                  setValue('user', { name: v.name, value: v.value })
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <TextInput
                  name="password"
                  label="Password"
                  errors={errors}
                  control={control}
                  disabled={!isUserSelected}
                />
                <Button variant="text" onClick={generatePassword} sx={{ mx: 1, mt: '4px' }}>
                  <AutorenewIcon />
                </Button>
              </Box>
              <TextInput
                name="address"
                label="Address"
                control={control}
                errors={errors}
                disabled={!isUserSelected}
              />
              <TextInput
                control={control}
                name="telephone"
                label="Telephone"
                errors={errors}
                disabled={!isUserSelected}
              />
              <Checkbox label="In possession" control={control} name="isInPossession" />
              <ControlledSelectAutocomplete
                name="cardId"
                control={control}
                errors={errors}
                label="Cards"
                url={`/api/bookings/cards/distinct`}
                column="num"
                columnValue="id"
                disabled={!isUserSelected}
                transformOption={(item) => ({ name: item.name, value: item.value })}
              />
              <TextInput control={control} name="note" label="Notes" multiline minRows={2} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '300px',
                  }}
                >
                  <Typography fontSize="14px" fontWeight={500} pb={1} color="text.secondary">
                    Booking confirmation file for listing
                  </Typography>
                  <CustomDropzone
                    setValue={setValue}
                    control={control}
                    name="bookingConfirmationFilename"
                    noMediaTitle="Attach file"
                    acceptedFormats={{
                      'application/pdf': ['.pdf'],
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                    }}
                    formatsAsLabel=".pdf, .jpg, jpeg, png"
                    viewType="droparea"
                    error={errors?.bookingConfirmationFilename?.message as string}
                    maxSize={2 * 2 ** 20}
                    downloadConfirmationUrl={`${baseURL}/api/bookings/download-booking-confirmation`}
                    isPdf={true}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '300px',
                  }}
                >
                  <Typography fontSize="14px" fontWeight={500} pb={1} color="text.secondary">
                    Screenshot of successful order
                  </Typography>
                  <CustomDropzone
                    setValue={setValue}
                    control={control}
                    name={'orderScreenshotFilename'}
                    noMediaTitle={'Attach file'}
                    acceptedFormats={{
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                    }}
                    formatsAsLabel=".jpg, jpeg, png"
                    viewType="droparea"
                    error={errors?.orderScreenshotFilename?.message as string}
                    maxSize={2 * 2 ** 20}
                    downloadConfirmationUrl={`${baseURL}/api/bookings/download-booking-confirmation`}
                    isPdf={false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Tags register={register} setValue={setValue} watch={watch} sx={{ mt: 2 }} />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button color="warning" onClick={handleCloseModal} variant="outlined">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isCreating || isEditing || isUploading || isUploadingScreenshot}
          >
            {listing ? (clone ? 'Clone' : 'Update') : 'Create'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
