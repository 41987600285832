import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { debounce } from '@mui/material'
import { GridColDef, GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'

import { handleFilterChangeHelper } from 'utils/tableFilterHelper'
import { camelToSnake } from 'utils/text'

interface Props {
  columns: GridColDef[]
  defaultSortModel?: { field: string; sort: 'asc' | 'desc' }
}

export const usePagination = ({
  columns,
  defaultSortModel = { field: 'id', sort: 'asc' },
}: Props) => {
  const paginationLS = JSON.parse(localStorage.getItem('pagination') || '{}')
  const location = useLocation()

  const [quickSearch, setQuickSearch] = useState('')

  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
    paginationLS?.[location.pathname]?.filterModel
  )
  const [sortModel, setSortModel] = useState<GridSortModel>(
    paginationLS?.[location.pathname]?.sortModel || []
  )
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    paginationLS?.[location.pathname]?.paginationModel || { page: 0, pageSize: 10 }
  )

  const [currentSearchValue, setCurrentSearchValue] = useState('')

  useEffect(() => {
    localStorage.setItem(
      'pagination',
      JSON.stringify({
        ...paginationLS,
        [location.pathname]: { filterModel, sortModel, paginationModel },
      })
    )
  }, [JSON.stringify(filterModel), JSON.stringify(sortModel), JSON.stringify(paginationModel)])

  const debouncedSetSearchValue = useCallback(
    debounce((value: string) => {
      setQuickSearch(value)
    }, 300),
    []
  )

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(e.target.value)
    debouncedSetSearchValue(e.target.value)
  }, [])

  const onFilterModelChange = useCallback((model: GridFilterModel) => {
    setFilterModel(model)
  }, [])
  const onSortModelChange = useCallback((model: GridSortModel) => {
    setSortModel(model)
  }, [])
  const onPaginationModelChange = useCallback((model: GridPaginationModel) => {
    setPaginationModel(model)
  }, [])

  const queryParams = useMemo(
    () => ({
      page: {
        size: paginationModel?.pageSize || 10,
        pageNumber: paginationModel?.page || 0,
      },
      filters: filterModel
        ? handleFilterChangeHelper(filterModel, columns).filter(
            (filter) => filter.values.length !== 0
          )
        : [],
      orderBy:
        (sortModel?.[0]?.field && camelToSnake(sortModel[0].field)) || defaultSortModel.field,
      direction: sortModel?.[0]?.sort || defaultSortModel.sort,
      quickSearch: quickSearch,
    }),
    [
      quickSearch,
      JSON.stringify(sortModel),
      JSON.stringify(filterModel),
      JSON.stringify(paginationModel),
    ]
  )

  return {
    queryParams,
    paginationDataGridProps: {
      searchValue: currentSearchValue,
      sortModel,
      paginationModel,
      filterModel,
      onSearchChange,
      onFilterModelChange,
      onSortModelChange,
      onPaginationModelChange,
    },
  }
}
