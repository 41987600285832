import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  EQUAL_STRING_OPERATOR,
  CONTAINS_STRING_OPERATOR,
  ALL_DATE_OPERATORS,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetMailsNewQuery } from 'services/dashboard/api'

import { NewMailsTabToolbar } from 'components/mails/mails/MailsTabToolbarNew'
import CustomDataGrid from 'components/table/DataGrid'

export type MailTabActions = 'SHOW_MESSAGE' | 'SHOW_MESSAGE_SINGLE' | ''

export const NewMailsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'sender',
        headerName: 'Sender',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        flex: 3,
        hideable: false,
        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'recipient',
        headerName: 'Recipient',
        type: 'string',
        flex: 3,

        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'subject',
        headerName: 'Subject',
        type: 'string',
        flex: 3.5,
        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'receivedAt',
        headerName: 'Received At',
        type: 'Datetime',
        flex: 2,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'savedAt',
        headerName: 'Saved at',
        type: 'Datetime',
        flex: 2,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('SHOW_MESSAGE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Show Message"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )

  const { queryParams, paginationDataGridProps } = usePagination({
    columns,
    defaultSortModel: { field: 'received_at', sort: 'desc' },
  })
  const { modal, setModal, singleRow, setSingleRow } = useActions<MailTabActions>()

  const { selectedRows, handleRowSelection } = useSelectedRows()

  const { data, isFetching, refetch } = useGetMailsNewQuery(
    //@ts-ignore
    { ...queryParams, showArchived: false },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<MailTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        //hideFooter
        onRowSelectionModelChange={handleRowSelection}
        {...paginationDataGridProps}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={NewMailsTabToolbar}
        onRefreshClick={refetch}
        //onFilterApplyClick={onFilterApplyClick}
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        checkboxSelection
      />
    </Box>
  )
}
