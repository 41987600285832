import { QueryTableState } from 'types/TableServerRequest'

import { EventCategory } from 'services/purchasing/types'

export type SelectOption = {
  id: string | number
  label: string
}

export interface GetListingsPayload extends QueryTableState {
  showArchived: boolean
}

export interface Ticket {
  id: number
  section: string
  row: string
  seat: string
  linkedFile: string
  saleStatusType: SaleStatusEnum
}

export type SuggestionEventCategory = {
  id: number
  name: string
  priceLocal: number
}

export interface BookingsListing {
  id: number
  eventCategory: EventCategory
  bookingId: string
  saleStatus: SaleStatusEnum
  orderedDateCet: string
  priceEur: number
  totalPriceEur: number
  tickets: Ticket[]
  user: string
  tags: Tag[]
  password: string
  bookingConfirmationFilename: string
  feeEur: number
  numberOfTickets: number
  orderScreenshotFilename: string
  ticketForm: number
  isInPossession: boolean
  telephone: string
  address: string
  note: string
  card: { id: number; num: string }
  priceLocal: number
  feeLocal: number
  sales: ListingSale[]
  salesPlatformEvents: {[key: string]: string}
  fanSection: string
}

export interface ListingSale {
  id: number
  numberOfTickets: number
  salePlatform: string
  salePrice: number
  saleUser: string
  splitType: number
}

enum SaleStatusEnum {
  NotSold = 0,
  OnSale = 5,
  Sold = 10,
  Hold = 20,
  PartiallySold = 30,
  Archived = 40,
  Unfilled = 50,
}

export interface RegisterSalePayload {
  listingId: number
  tickets: {
    id: number
    firstName?: string | null
    lastName?: string | null
    email?: string | null
  }[]
  salePriceEur: number
  salesPlatformId: number
  customerName?: string | null
  customerEmail?: string | null
  sendTickets?: boolean
  sendEmail?: string | null
  trackingNumber?: string | null
  salesPlatformUserId: number
  platformOrderId: string
}

export interface EditSalePayload {
  id: number
  priceChange: number
  splitType?: number
}

export interface RegisterOnSalePayload {
  listingId: number
  salesPlatformUserId: number | null
  salesPlatformData: {
    eventId: string
  } | null
  saleUnits: {
    salePriceEur: number
    splitType: number
    numberOfTickets: number
  }[]
  platformListingId?: string | null
}

export interface BookingsCard {
  id: number
  num: string
  name: string
  month: string
  year: string
  needApprove: boolean
  cardType: number
  balance: number
  botsCount: number
  cvv: string
}

export const CARD_TYPES = {
  '0': 'Other',
  '1': 'Visa',
  '2': 'MasterCard',
  '3': 'American Express',
  '4': 'Diners',
  '5': 'Discover',
} as const
export const CARD_TYPES_OPTIONS = Object.entries(CARD_TYPES).map(([value, label]) => ({
  value,
  label,
  name: label,
}))

export type NewCard = Omit<BookingsCard, 'botsCount' | 'id'>
export type EditCard = Omit<BookingsCard, 'botsCount'>

export interface ArchiveListingPayload {
  archive: boolean
  listingIds: number[]
}

export interface ArchiveListingResponse {
  msc: string
  type?: string
}

export interface BookingsSalesPlatform {
  id: number
  name: string
  site: string
  description: string
  integration: boolean
  platformUsers: PlatformUser[]
  createdAt: string
}

export interface UtilitySalesPlatform {
  id: number
  name: string
  site: string
  description: string
  integration: boolean
  platformUsers: {
    id: number
    salesPlatformId: number
    platformUserId: string
    platformUserName: string
    tokenSalt: string
  }[]
  createdAt: string
}

interface PlatformUser {
  id: number
  salesPlatformId: number
  platformUserId: string
  platformUserName: string
  tokenSalt: string
}

export type SuggestionUser = {
  username: string
  password: string
}

export interface SuggestionsResponse<T> {
  suggestions: T
}

export interface NewBookingListing {
  eventCategoryId: number
  priceLocal: number
  orderedDateCet: string
  user: string
  password: string
  address?: string | null
  telephone?: string | null
  cardId: number
  feeLocal: number
  note?: string | null
  tagIds: (number | undefined)[]
  //numberOfTickets: number
  bookingConfirmationFilename: string
  orderScreenshotFilename: string
  originalListingId?: number
  ticketForm: number
  bookingId?: string | null
  tickets: {
    section: string | null
    row: string | null
    seat: string | null
  }[]
  fanSection?: string | null
}

export interface EditBookingListing {
  eventCategoryId: number
  orderedDateCet: string
  tagIds: (number | undefined)[]
  priceLocal: number
  feeLocal: number
  user: string
  password: string
  address?: string | null
  telephone?: string | null
  note?: string | null
  cardId: number
  bookingId?: string | null
  ticketForm: number
  //numberOfTickets: number
  bookingConfirmationFilename: string
  orderScreenshotFilename: string
  id: number
  tickets: {
    id?: number
    section: string | null
    row: string | null
    seat: string | null
  }[]
  fanSection?: string | null
}

export interface TagResponse {
  id: number
  color: string
  caption: string
}

export type Tag = SelectOption & { id: number; color: string }

export type SuggestionEvent = {
  id: number | string
  name: string
  categories: SuggestionEventCategory[]
}

export interface NewSalePlatform {
  name: string
  site?: string | null
  description: string
  username: string
  token?: string | null
}

export interface EditSalePlatform extends NewSalePlatform {
  id: number
}

export const SaleStatus = {
  '0': 'Not Sold',
  '5': 'On Sale',
  '10': 'Sold',
  '20': 'Hold',
  '30': 'Partially Sold',
  '40': 'Archived',
  '50': 'Unfilled',
} as const

export const SALE_STATUSES = Object.entries(SaleStatus).map(([value, label]) => ({
  value,
  label,
}))

export interface AddTag {
  data: {
    id?: number
    color: string
    caption: string
  }[]
}

export interface Sale {
  id: number
  sentStatus: SendStatusType //TODO: add enum
  tickets: {
    id: number
    sentStatusType: SendStatusType //TODO: add enum
    section: string
    row: string
    seat: string
    linkedFile: string
    holderFirstName: string
    holderLastName: string
    holderEmail: string
  }[]
  eventCategory: {
    id: number
    name: string
    eventId: number
    eventName: string
    eventDate: string
    eventGroupId: number
    eventGroupName: string
    currency: {
      name: string
      rateToEur: number
    }
  }
  accountDetails: {
    email: string
    password: string
  }
  platformOrderId: string
}

enum SendStatusType {
  NotSent = 0,
  Sent = 10,
  Deferred = 20,
  PartiallySent = 30,
}

export const SENT_STATUS_TYPE = {
  '0': 'Not Sent',
  '10': 'Sent',
  '20': 'Deferred',
  '30': 'Partially Sent',
} as const
export type SentStatusType = keyof typeof SENT_STATUS_TYPE

export const SENT_STATUS_OPTIONS_TO_SELECT = Object.entries(SENT_STATUS_TYPE).map(
  ([value, label]) => ({
    value,
    label,
  })
)

export interface SendTicketsPayload {
  id: number
  tickets: TicketToSend[]
  accountDetails: {
    email: string
    password: string
  }
  platformOrderId?: string | null
  send: SendType
}

export enum SendType {
  DONT_SEND = 0,
  MARK_AS_SENT = 1,
  SEND_EMAIL = 2,
}

interface TicketToSend {
  id: number
  linkedFile?: string | null
  holderFirstName?: string | null
  holderLastName?: string | null
  holderEmail?: string | null
  send: boolean
}
