import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box/Box'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'
import { showNotification } from 'utils/showNotification'

import {
  useCreateScrapTasksNewMutation,
  useEditScrapTasksNewMutation,
} from 'services/dashboard/api'
import { ScrapTaskNew } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import DatePicker from 'components/common/DatePicker'
import Switch from 'components/common/Switch/index'
import TextInput from 'components/common/TextInput/index'

import { EDIT_SCRAP_TASKS } from './validation'

interface FormValues {
  name: string
  enable: boolean
  subjects: (string | undefined)[]
  senders: (string | undefined)[]
  recipients: (string | undefined)[]
  excludedSenders: (string | undefined)[]
  excludedSubjects: (string | undefined)[]
  excludedRecipients: (string | undefined)[]
  remarkEmails: boolean
  dateFrom: string
  dateTo: string
}

interface Props {
  onClose: () => void
  scrapTask?: ScrapTaskNew
}

export const EditScrapTasksNewModal: FC<Props> = ({ scrapTask, onClose }) => {
  const [editScrapTasks, { isLoading }] = useEditScrapTasksNewMutation()
  const [createScrapTasks, { isLoading: isCreateLoading }] = useCreateScrapTasksNewMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(EDIT_SCRAP_TASKS),
    defaultValues: {
      name: scrapTask?.name || '',
      enable: scrapTask?.enable || false,
      subjects: scrapTask?.subjects || [],
      senders: scrapTask?.senders || [],
      recipients: scrapTask?.recipients || [],
      excludedSenders: scrapTask?.excludedSenders || [],
      excludedSubjects: scrapTask?.excludedSubjects || [],
      excludedRecipients: scrapTask?.excludedRecipients || [],
      remarkEmails: false,
      dateFrom: scrapTask?.dateFrom || '',
      dateTo: scrapTask?.dateTo || '',
    },
  })
  const onSubmit = async ({
    excludedSenders,
    excludedRecipients,
    excludedSubjects,
    remarkEmails,
    dateFrom,
    dateTo,
    ...rest
  }: FormValues) => {
    if (scrapTask)
      editScrapTasks({
        ...rest,
        id: scrapTask.id,
        dateFrom: formatToLocaleTimezone(dateFrom, 'yyyy-MM-dd'),
        dateTo: formatToLocaleTimezone(dateTo, 'yyyy-MM-dd'),
        remarkEmails,
        excludedSenders,
        excludedRecipients,
        excludedSubjects,
      })
        .unwrap()
        .then(() => {
          showNotification('Task edited', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    else {
      createScrapTasks({
        ...rest,
        dateFrom: formatToLocaleTimezone(dateFrom, 'yyyy-MM-dd'),
        dateTo: formatToLocaleTimezone(dateTo, 'yyyy-MM-dd'),
        excludedSenders,
        excludedRecipients,
        excludedSubjects,
      })
        .unwrap()
        .then(() => {
          showNotification('Task created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }
  const handleArraySetValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: any
  ) => {
    setValue(name, e.target.value.split(','))
  }
  return (
    <AppModal
      title={scrapTask ? `Edit Scrap Task: ${scrapTask.name}` : 'Create Scrap Task'}
      onClose={onClose}
    >
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '700px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          {!scrapTask && <TextInput control={control} name="name" label="Name" errors={errors} />}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker
              name="dateFrom"
              control={control}
              label="From"
              errors={errors}
              inputFormat="yyyy-MM-dd"
            />
            <DatePicker
              name="dateTo"
              control={control}
              label="To"
              errors={errors}
              inputFormat="yyyy-MM-dd"
            />
          </Box>
          <Switch sx={{ alignSelf: 'center' }} control={control} name={'enable'} />
          <TextInput
            control={control}
            name="subjects"
            label="Subjects  (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'subjects')}
          />
          <TextInput
            control={control}
            name="senders"
            label="Senders (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'senders')}
          />
          <TextInput
            control={control}
            name="recipients"
            label="Recipients (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'recipients')}
          />
          <TextInput
            control={control}
            name="excludedSenders"
            label="Excluded Senders (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedSenders')}
          />
          <TextInput
            control={control}
            name="excludedSubjects"
            label="Excluded Subjects (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedSubjects')}
          />
          <TextInput
            control={control}
            name="excludedRecipients"
            label="Excluded Recipients (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedRecipients')}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          {scrapTask && (
            <Switch
              control={control}
              name="remarkEmails"
              disabledLabel=""
              enabledLabel="Remark emails"
            />
          )}
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading || isCreateLoading}
            fullWidth={isMobileOnly}
          >
            {scrapTask ? 'Update' : 'Create'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
