import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import InfoIcon from '@mui/icons-material/Info'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'

import { showNotification } from 'utils/showNotification'

import { useAddProxiesMutation } from 'services/bots/api'
import { CountriesTransform } from 'services/bots/types'

import { ADD_PROXIES_SCHEMA } from 'components/bots/proxies/AddProxiesModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput/index'

interface Props {
  onCLose: () => void
  countries: CountriesTransform[] | undefined
}

interface FormValues {
  host: string
  minPort: string
  maxPort: string
  quantity: number
  maxBotsCount: number
  tags: string[]
  countryDistribution: {
    countryId: {
      value: string
      name: string
    }
    percent: number
    tags: string[]
  }[]
  usernamePattern: string
  passwordPattern: string
}

export const AddProxiesModal: FC<Props> = ({ countries, onCLose }) => {
  const [addProxies, { isLoading: isCreateProxies }] = useAddProxiesMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(ADD_PROXIES_SCHEMA),
    defaultValues: {
      host: '',
      minPort: '',
      maxPort: '',
      quantity: 1,
      maxBotsCount: 1,
      tags: [],
      countryDistribution: [],
      usernamePattern: '',
      passwordPattern: '',
    },
  })
  const { fields, prepend } = useFieldArray({
    control,
    name: 'countryDistribution',
  })
  const onSubmit = async (proxies: FormValues) => {
    console.log(proxies)
    await addProxies({
      ...proxies,
      countryDistribution: proxies.countryDistribution.map((v) => ({
        ...v,
        countryId: +v.countryId.value,
      })),
    })
      .unwrap()
      .then(() => {
        showNotification('Proxies successfully added', 'success')
        onCLose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const handleChangeProxyTag = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: any
  ) => {
    setValue(name, e.target.value.split(','))
  }


  return (
    <AppModal title="Add Proxies" onClose={onCLose}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '750px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              p: 1,
              gap: 2,
              mb: 2,
            }}
          >
            <InfoIcon color="primary" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="body2">
                Username and Password Pattern: <br />
                {`for country_code use {country}; for random strings use regex`}
              </Typography>
              <Typography variant="body2" component="p">
                Usage example:{' '}
                <Typography
                  variant="body2"
                  component="em"
                >{`newproxy-country_{country}-session_([0-9a-z]{{4}})-skipstatic-1`}</Typography>
              </Typography>
            </Box>
          </Box>
          <TextInput control={control} name="host" label="Host" errors={errors} sx={{ flex: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <TextInput
              control={control}
              name="minPort"
              label="Min Port"
              errors={errors}
              sx={{ flex: 1 }}
            />
            <TextInput
              control={control}
              name="maxPort"
              label="Max Port"
              errors={errors}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextInput
            control={control}
            name="usernamePattern"
            label="Username Pattern"
            errors={errors}
          />
          <TextInput
            control={control}
            name="passwordPattern"
            label="Password Pattern"
            errors={errors}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <TextInput
              control={control}
              name="quantity"
              label="Number Of Proxies"
              errors={errors}
              type="number"
            />
            <TextInput
              control={control}
              name="maxBotsCount"
              label="Max Bots Count"
              errors={errors}
              type="number"
            />
          </Box>
          <TextInput
            control={control}
            name="tags"
            label="Proxy Tags (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleChangeProxyTag(e, 'tags')}
          />
          <Button
            variant="contained"
            onClick={() => {
              prepend({
                countryId: {
                  value: countries?.[0]?.value || '0',
                  name: countries?.[0]?.name || 'Any',
                },
                percent: 0,
                tags: [],
              })
            }}
          >
            Add Country
          </Button>
          {fields.map((v, ind) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
              key={v.id}
            >
              <ControlledSelectAutocomplete
                name={`countryDistribution.${ind}.countryId`}
                label="Counrtry"
                errors={errors?.countryDistribution?.[ind]?.countryId?.message}
                control={control}
                selectOptions={countries}
                sx={{ flex: 1 }}
              />
              <TextInput
                name={`countryDistribution.${ind}.percent`}
                label="Distribution Percent"
                type="number"
                errors={errors?.countryDistribution?.[ind]?.percent?.message}
                control={control}
                sx={{ flex: 1 }}
              />
              <TextInput
                control={control}
                name={`countryDistribution.${ind}.tags`}
                label="Proxy Tags (separated by comma)"
                errors={errors}
                onChangeHandler={(e) => handleChangeProxyTag(e, `countryDistribution.${ind}.tags`)}
                sx={{ flex: 1 }}
              />
            </Box>
          ))}
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onCLose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isCreateProxies}
            fullWidth={isMobileOnly}
          >
            Add
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
