import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { rolesEnum } from 'services/users/types'

import ProtectedRoute from 'components/auth/ProtectedRoute'
import { ManageListing } from 'components/bookings/listings/ManageListing'

import { AccountsBotTab } from 'pages/bots/AccountsTab/index'
import { BotsTab } from 'pages/bots/BotsTab/index'
import { ProxiesTab } from 'pages/bots/ProxiesTab/index'
import { BotStationTab } from 'pages/dashboard/BotStationTab/index'
import { ChartsTab } from 'pages/dashboard/ChartsTab/index'
import { LogsTab } from 'pages/dashboard/LogsTab/index'
import Mails from 'pages/mails/index'

import MainLayout from '../components/main-layout'
import { NotImplemented } from './NotImplemented'
import Login from './auth/login'
import Bookings from './bookings'
import CardsTab from './bookings/CardsTab'
import Listings from './bookings/ListingsTab'
import SalePlatforms from './bookings/SalePlatforms'
import SalesTable from './bookings/SalesTab'
import { Bots } from './bots'
import { Dashboard } from './dashboard'
import { ReportsTab } from './dashboard/ReportsTab'
import Error from './error'
import Events from './events'
import EventGroupsTable from './events/EventGroupsTab/EventGroupsTable'
import EventsTable from './events/EventsTab/EventsTable'
import { MailsTabByVersion } from './mails/MailTabByVersion'
import { ScrapTasksTabByVersion } from './mails/ScrapTasksTabByVersion'
import NotFound from './not-found'
import Purchasing from './purchasing'
import AccountsTab from './purchasing/AccountsTab'
import GoalsTab from './purchasing/GoalsTab'
import InWorkTab from './purchasing/InWorkTab'
import ListingsTab from './purchasing/ListingsTab'
import Root from './root'
import Users from './users'
import UsersList from './users/List'
import Logs from './users/Logs'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route index element={<Navigate to="/bot-dashboard" replace />} />
      <Route element={<MainLayout />}>
        <Route
          path="bot-dashboard"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="charts" replace />} />
          <Route path="charts" element={<ChartsTab />} />
          <Route
            path="bot-station"
            element={
              <ProtectedRoute allowedRoles={[rolesEnum.Admin]}>
                <BotStationTab />
              </ProtectedRoute>
            }
          />
          <Route
            path="logs"
            element={
              <ProtectedRoute allowedRoles={[rolesEnum.Admin]}>
                <LogsTab />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports"
            element={
              <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
                <ReportsTab />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="mails"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
              <Mails />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="mails" replace />} />
          <Route path="mails" element={<MailsTabByVersion />} />
          <Route
            path="scrap-tasks"
            element={
              <ProtectedRoute allowedRoles={[rolesEnum.Admin]}>
                <ScrapTasksTabByVersion />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="bookings"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
              <Bookings />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="listings" replace />} />
          <Route path="listings" element={<Listings />} />
          <Route path="listings/manage/:id" element={<ManageListing />} />
          <Route path="sales" element={<SalesTable />} />
          <Route path="cards" element={<CardsTab />} />
          <Route path="sale-platforms" element={<SalePlatforms />} />
        </Route>
        <Route
          path="events"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
              <Events />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="events" replace />} />
          <Route path="events" element={<EventsTable />} />
          <Route path="event-groups" element={<EventGroupsTable />} />
        </Route>
        <Route
          path="bots"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager]}>
              <Bots />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="bots" replace />} />
          <Route path="accounts" element={<AccountsBotTab />} />
          <Route path="proxies" element={<ProxiesTab />} />
          <Route path="bots" element={<BotsTab />} />
        </Route>
        <Route
          path="users"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin]}>
              <Users />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="list" replace />} />
          <Route path="list" element={<UsersList />} />
          <Route path="logs" element={<Logs />} />
        </Route>
        <Route
          path="purchasing"
          element={
            <ProtectedRoute allowedRoles={[rolesEnum.Admin, rolesEnum.Manager, rolesEnum.Operator]}>
              <Purchasing />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="accounts" replace />} />
          <Route path="in-work" element={<InWorkTab />} />
          <Route path="accounts" element={<AccountsTab />} />
          <Route path="goals" element={<GoalsTab />} />
          <Route path="listings" element={<ListingsTab />} />
        </Route>
        <Route path="not-implemented" element={<NotImplemented />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="login" element={<Login />} />
    </Route>
  )
)

export default router
