import React from 'react'

import { useVersionContext } from '..'
import { NewScrapTasksTab } from '../NewScrapTasksTab'
import { OldScrapTasksTab } from '../OldScrapTasksTab'

export const ScrapTasksTabByVersion = () => {
  const { version } = useVersionContext()
  return version === 'v1' ? <OldScrapTasksTab /> : <NewScrapTasksTab />
}
