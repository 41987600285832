import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'

import { ALL_NUMBER_OPERATORS, ALL_STRING_OPERATORS } from 'constants/table-filters'

import { showNotification } from 'utils/showNotification'

import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useEditBotStationMutation, useGetBotsStationsQuery } from 'services/dashboard/api'
import { BotStation } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import CustomDataGrid from 'components/table/DataGrid'

import { BotStationToolbar } from '../BotStationToolbar'

interface Props {
  onClose: () => void
  stationsIds: number[]
}

export const CopySettingsModal = ({ onClose, stationsIds }: Props) => {
  const columns = useMemo<GridColDef<BotStation>[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'ip',
        headerName: 'IP address',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'distinctiveNumber',
        headerName: 'Distinctive Number',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
    ],
    []
  )

  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetBotsStationsQuery(queryParams)
  const [updateStation, { isLoading }] = useEditBotStationMutation()

  const onSubmit = () => {
    console.log(selectedRows)
    const copyFrom = data?.data.find((v) => selectedRows[0] === v.id)
    if (copyFrom) {
      const { id: _, browserArgs, browserMode, eventGroupDetails, proxyMasks, ...rest } = copyFrom
      if (eventGroupDetails !== null && proxyMasks)
        Promise.all(
          stationsIds.map((v) =>
            updateStation({
              ...rest,
              id: v,
              browserConfig: browserArgs,
              browser: +browserMode,
              eventGroupId: eventGroupDetails.id,
              proxyTags: proxyMasks,
            }).unwrap()
          )
        )
          .then(() => {
            showNotification("Stations' settings successfully copied", 'success')
            onClose()
          })
          .catch(() => showNotification('Failed to copy station settings', 'error'))
    }
  }

  return (
    <AppModal title="Copy settings" onClose={onClose} loading={isLoading}>
      <AppModal.Wrapper
        sx={{ width: '700px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
          }}
        >
          <Typography>Select a desired bot station to copy from</Typography>
          <Box sx={{ height: '100%', width: '100%', pt: 1 }}>
            <CustomDataGrid
              rows={data?.data || []}
              columns={columns}
              rowCount={data?.page?.totalElements || 0}
              loading={isFetching}
              onRowSelectionModelChange={handleRowSelection}
              selectedRows={data?.data.filter((v) => selectedRows.includes(v.id)) || []}
              {...paginationDataGridProps}
              toolbar={BotStationToolbar}
              hideActions
              checkboxSelection
            />
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button
            onClick={onSubmit}
            variant="contained"
            fullWidth
            disabled={selectedRows.length != 1}
          >
            Copy
          </Button>
          <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
