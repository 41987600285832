import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useEnableTasksMutation } from 'services/dashboard/api'

import ConfirmModal from 'components/common/ConfirmModal/index'
import { EditScrapTasksModal } from 'components/mails/scrap-tasks/EditScrapTasksModal/index'
import TableToolbar from 'components/table/TableToolbar/index'

import { ScrapTasksTabMActions } from 'pages/mails/OldScrapTasksTab'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: ScrapTasksTabMActions
  setModal: React.Dispatch<React.SetStateAction<ScrapTasksTabMActions>>
  singleRow: any
}

export const ScrapTasksToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [enableTask, { isLoading: isEnableLoading }] = useEnableTasksMutation()
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const confirmMessage =
    modal === 'ENABLE'
      ? `Are you sure you want to ${selectedRows[0]?.enable ? 'disable' : 'enable'} task${
          selectedRows.length > 1 ? 's' : ''
        }?`
      : `Are you sure you want to ${singleRow?.enable ? 'disable' : 'enable'} task?`
  const confirmEnableTask = async () => {
    enableTask({
      task_names:
        modal === 'ENABLE'
          ? selectedRows.length > 1
            ? selectedRows.map((v: any) => v.name)
            : [selectedRows[0].name]
          : [singleRow?.name],
    })
      .unwrap()
      .then(() => {
        showNotification(
          modal === 'ENABLE'
            ? `Task successfully ${selectedRows[0].enable ? 'disabled' : 'enabled'}`
            : `Task successfully ${singleRow?.enable ? 'disabled' : 'enabled'}`,
          'success'
        )
        setModal('')
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  return (
    <>
      <TableToolbar
        onChange={onSearchChange}
        searchValue={searchValue}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Task</Typography>
                  </MenuItem>
                )}
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('ENABLE')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">
                      {selectedRows[0].enable ? 'Disable Task' : 'Enable Task'}
                    </Typography>
                  </MenuItem>
                )}
                {selectedRows.length > 1 &&
                  (selectedRows.length === selectedRows.filter((v: any) => v.enable).length ||
                    selectedRows.length === selectedRows.filter((v: any) => !v.enable).length) && (
                    <MenuItem
                      onClick={() => {
                        setModal('ENABLE')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">
                        {selectedRows[0].enable ? 'Disable Tasks' : 'Enable Tasks'}
                      </Typography>
                    </MenuItem>
                  )}
              </Menu>
            </Box>
          </Box>
        }
      />
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditScrapTasksModal
          onClose={() => {
            setModal('')
          }}
          scrapTask={modal === 'EDIT' ? selectedRows[0] : singleRow}
        />
      )}
      {(modal === 'ENABLE' || modal === 'ENABLE_SINGLE') && (
        <ConfirmModal
          onConfirm={confirmEnableTask}
          onClose={() => {
            setModal('')
          }}
          open={true}
          loading={isEnableLoading}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
    </>
  )
}
