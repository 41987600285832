import React, { FC } from 'react'
import { Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { formatDate } from 'utils/dateFormat'
import { isValidDate } from 'utils/dateHelper'
import { capitalize } from 'utils/text'

interface Props extends Omit<DatePickerProps<any, any>, 'onChange' | 'value' | 'renderInput'> {
  control: any
  name: string
  label: string
  errors?: any
  disableKeyboard?: boolean
  sx?: any
  inputFormat?: string
  withoutTimeReset?: boolean
}

const DatePicker: FC<Props> = ({
  control,
  errors,
  name,
  label,
  disableKeyboard,
  sx,
  inputFormat = 'yyyy-MM-dd HH:mm:ss',
  withoutTimeReset,
  ...rest
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disableKeyboard) {
      event.preventDefault()
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DesktopDatePicker
            label={label}
            inputFormat={inputFormat}
            value={value}
            onChange={(date) =>
              onChange(isValidDate(date) && date !== null ? formatDate(date, !!withoutTimeReset) : '')
            }
            {...rest}
            renderInput={(params) => (
              <TextField
                {...params}
                size="medium"
                fullWidth
                onKeyDown={handleKeyDown}
                error={!!errors[name]}
                helperText={errors[name]?.message && capitalize(errors[name]?.message)}
                sx={{ height: '70px', ...sx }}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
