import React, { CSSProperties, ReactNode } from 'react';
import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box/Box';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography/Typography';

import calculateFileSize from 'utils/calculateFileSize';

import DownloadedFile from 'components/common/CustomDropzone/DownloadedFile/index';

import UploadedFile from './UploadedFile';

interface Props {
  control: any
  name: string
  noMediaTitle: ReactNode
  error: string | undefined
  wrapperStyles?: CSSProperties
  maxSize: number
  formatsAsLabel: string
  setValue: any
  viewType: 'button' | 'droparea'
  downloadConfirmationUrl?: string
  isPdf: boolean
  acceptedFormats?: { [key: string]: string[] }
}

export const CustomDropzone = (props: Props) => {
  const {
    control,
    name,
    error,
    wrapperStyles,
    maxSize,
    formatsAsLabel,
    viewType,
    setValue,
    downloadConfirmationUrl,
    isPdf,
    acceptedFormats,
  } = props

  const renderZoneAs = {
    button: <DndButton />,
    droparea: <DndZone maxSize={maxSize} formatsAsLabel={formatsAsLabel} />,
  }
  const isOpera = /OPR/.test(navigator.userAgent)

  return (
    <Box
      style={wrapperStyles}
      sx={{
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        // cursor: 'pointer',
      }}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Dropzone onDrop={onChange} maxFiles={1} accept={acceptedFormats}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    display: 'flex',
                  }}
                >
                  <input id={name} {...getInputProps()} onBlur={onBlur} name={name} />
                  {/*IF  VALUE */}
                  {!!value?.length && !error ? (
                    <>
                      {/*IF viewType DROPAREA AND VALUE STRING*/}
                      {viewType === 'droparea' && typeof value === 'string' && (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <DownloadedFile
                            downloadUrl={downloadConfirmationUrl}
                            isEditForm={true}
                            fileName={value}
                          />
                          <ClearIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            onClick={() => {
                              setValue(name, [])
                            }}
                          />
                        </Box>
                      )}
                      {/*IF viewType DROPAREA AND VALUE FILE*/}
                      {viewType === 'droparea' && typeof value === 'object' && (
                        <UploadedFile
                          key={value[0].name}
                          file={value[0]}
                          onRemoveFile={() => setValue(name, [])}
                          icon={isPdf ? <PictureAsPdfIcon /> : <AttachFileIcon />}
                        />
                      )}
                      {/*IF viewType BUTTON AND VALUE STRING*/}
                      {viewType === 'button' && typeof value === 'string' && (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '200px',
                            border: '1px solid #007bff',
                            borderRadius: '4px',
                            gap: '2%',
                            p: '0.5rem 0.5rem',
                          }}
                        >
                          <AttachFileIcon fontSize="medium" color="primary" />
                          <DownloadedFile

                            downloadUrl={downloadConfirmationUrl}
                            isEditForm={true}
                            fileName={value}
                            sx={{ width: '70%' }}
                          />
                          <ClearIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            onClick={() => {
                              setValue(name, [])
                            }}
                          />
                        </Box>
                      )}
                      {/*IF viewType BUTTON AND VALUE FILE*/}
                      {viewType === 'button' && typeof value === 'object' && (
                        <UploadedFile
                          key={value[0].name}
                          file={value[0]}
                          onRemoveFile={() => setValue(name, [])}
                          icon={
                            isPdf ? (
                              <PictureAsPdfIcon fontSize="small" />
                            ) : (
                              <PhotoLibraryIcon fontSize="small" />
                            )
                          }
                          sx={{ height: '50px' }}
                        />
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {renderZoneAs[viewType]}
                      {isOpera && (
                        <InputLabel
                          sx={{ pt: 1, cursor: 'pointer', fontWeight:'bold', fontSize:'14px'}}
                          onClick={(e) => e.stopPropagation()}
                          htmlFor={name}
                        >
                          Select {isPdf ? 'File' : 'Image'} from clipboard
                        </InputLabel>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Dropzone>
          )
        }}
      />
      <Typography
        color="error"
        sx={{
          height: '0.75rem',
          fontFamily: 'Open Sans, sans-serif',
          fontWeight: 500,
          fontSize: '0.75rem',
          lineHeight: 1,
          mt: 0.25,
          ml: 1,
        }}
      >
        {error}
      </Typography>
    </Box>
  )
}

interface DndZoneProps {
  maxSize: number
  formatsAsLabel: string
}

const DndZone = ({ maxSize, formatsAsLabel }: DndZoneProps) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: '#F0F1F3',
        padding: '24px',
        overflow: 'hidden',
        textAlign: 'center',
        cursor: 'pointer',
        height: '100px',
        width: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#D2D4D5',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D2D4D5' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e")`,
      }}
    >
      <Box>
        <Typography fontSize="14px" fontWeight={500}>
          {'Drag & drop or browse a file'}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} color="text.secondary">
          Max size is {calculateFileSize(maxSize)}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} color="text.secondary">
          {formatsAsLabel} only
        </Typography>
      </Box>
    </Box>
  )
}

const DndButton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        width: '200px',
        border: '1px solid #007bff',
        borderRadius: '4px',
        gap: 0.5,
        p: '0.5rem 0.5rem',
        cursor: 'pointer',
      }}
    >
      <AttachFileIcon fontSize="medium" color="primary" />
      <Typography fontSize="16px" fontWeight={500}>
        Click to attach file
      </Typography>
    </Box>
  )
}
