import AxiosInterceptor, { baseURL } from 'api'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

import { AuthProvider } from 'context/AuthContext'

const Root = () => {
  console.log('env test', baseURL)
  return (
    <>
      <AuthProvider>
        <CssBaseline />
        <AxiosInterceptor />
        <Outlet />
        <ScrollRestoration />
      </AuthProvider>
    </>
  )
}

export default Root
