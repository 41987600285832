import React, { useMemo } from 'react'

import PeopleIcon from '@mui/icons-material/People'
import SubjectIcon from '@mui/icons-material/Subject'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_STRING_OPERATORS,
  NOT_EQUAL_SELECT_OPERATOR,
  SELECT_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetScrapTasksQuery } from 'services/dashboard/api'

import { ScrapTasksToolbar } from 'components/mails/scrap-tasks/ScrapTasksToolbar/index'
import CustomDataGrid from 'components/table/DataGrid/index'

const ENABLED_OPTIONS = [
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
]
export type ScrapTasksTabMActions = 'EDIT' | 'ENABLE' | 'EDIT_SINGLE' | 'ENABLE_SINGLE' | ''
export const OldScrapTasksTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'String',
        flex: 3,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'enable',
        headerName: 'Status',
        type: 'singleSelect',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueOptions: ENABLED_OPTIONS,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (row.enable ? 'Enabled' : 'Disabled'),
      },
      {
        field: 'ticket_system.name',
        headerName: 'Ticket System ',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row.ticketSystemName,
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'subjects',
        headerName: 'Subjects',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderCell: ({ row }) =>
          row?.subjects.length ? (
            <Tooltip
              placement="right"
              title={
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {row.subjects.map((item: string, ind: number) => (
                    <li key={ind} style={{ marginBottom: '4px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              }
            >
              <Box>
                <SubjectIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
              </Box>
            </Tooltip>
          ) : (
            'ALL'
          ),
      },
      {
        field: 'senders',
        headerName: 'Senders',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderCell: ({ row }) =>
          row?.senders.length ? (
            <Tooltip
              placement="right"
              title={
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {row.senders.map((item: string, ind: number) => (
                    <li key={ind} style={{ marginBottom: '4px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              }
            >
              <Box>
                <PeopleIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
              </Box>
            </Tooltip>
          ) : (
            'ALL'
          ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Task"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('ENABLE_SINGLE')
                setSingleRow(params.row)
              }}
              label={params.row.enable ? 'Disable Task' : 'Enable Task'}
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({
    columns,
    defaultSortModel: { field: 'name', sort: 'asc' },
  })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetScrapTasksQuery(queryParams)
  const { modal, setModal, singleRow, setSingleRow } = useActions<ScrapTasksTabMActions>()

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<ScrapTasksTabMActions>
        getRowId={(row) => row.name}
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((v: any) => selectedRows.includes(v.name)) || []}
        toolbar={ScrapTasksToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}
