import React, { ChangeEvent, JSXElementConstructor } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGridPro, DataGridProProps, GridColDef, GridFilterModel } from '@mui/x-data-grid-pro'
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel'

import NoDataInDataGridTable from 'components/table/NoDataInTable'

interface Props<T> extends DataGridProProps {
  rows: any[]
  columns: GridColDef[]
  rowCount: number
  loading: boolean
  filterModel?: GridFilterModel
  onSearchChange: (v: ChangeEvent<HTMLInputElement>) => void
  searchValue: string
  selectedRows: any[]
  onExportButtonClick?: () => void
  toolbar?: JSXElementConstructor<any> | null | undefined
  modal?: T
  setModal?: React.Dispatch<React.SetStateAction<T>>
  singleRow?: any
  onFilterApplyClick?: () => void
  onRefreshClick?: () => void
  hideActions?: boolean
}

const CustomDataGrid = <T,>({
  rows,
  columns,
  loading,
  onSearchChange,
  searchValue,
  rowCount,
  selectedRows,
  onExportButtonClick,
  toolbar,
  onRowSelectionModelChange,
  rowSelection = true,
  modal,
  setModal,
  singleRow,
  onFilterApplyClick,
  onRefreshClick,
  slots,
  hideActions,
  slotProps,
  pagination = true,
  ...rest
}: Props<T>) => {
  const handleRowSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(newSelectionModel, {})
    }
  }

  return (
    <DataGridPro
      sx={{
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      }}
      {...rest}
      columnHeaderHeight={48}
      rowHeight={45}
      rows={rows || []}
      columns={columns}
      rowCount={rowCount}
      loading={loading}
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      pagination={pagination}
      pageSizeOptions={[10, 25, 50, 100]}
      filterDebounceMs={500}
      rowSelection={rowSelection}
      onRowSelectionModelChange={handleRowSelectionChange}
      slots={{
        toolbar: toolbar,
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoDataInDataGridTable,
        ...slots,
        detailPanelCollapseIcon: KeyboardArrowRightIcon,
        detailPanelExpandIcon: KeyboardArrowDownIcon,
      }}
      slotProps={{
        toolbar: {
          onSearchChange: onSearchChange,
          onFilterApplyClick: onFilterApplyClick,
          searchValue,
          selectedRows,
          onExportButtonClick,
          modal,
          setModal,
          hideActions,
          singleRow,
          onRefreshClick,
        },
        ...slotProps,
      }}
    />
  )
}

export default CustomDataGrid
